<template>
  <l-map
    v-if="routeCoordinates.length > 0"
    class="VueLeafMap"
    :zoom="zoom"
    :center="MapCenter"
    :bounds="bounds"
  >
    <!-- :max-bounds="maxBounds" -->
    <!-- style="height: 400px" -->
    <l-tile-layer
      :url="url"
      :attribution="attribution"
    />
    <l-control-fullscreen
      position="topleft"
      :options="{ title: { false: 'Full Screen', true: 'Dashboard View' } }"
    />
    <l-control
      v-if="tripPlayBack"
      position="topright"
    >
      <div class="d-flex gap-05">
        <v-btn
          v-if="!isPlaying"
          color="primary"
          small
          @click="setIntervalTime"
        >
          {{ intervalTimeOptions.indexOf(intervalTime) + 1 }}x
        </v-btn>
        <v-btn
          color="primary"
          small
          @click="isPlaying ? tripPause() : tripPlay()"
        >
          <v-icon v-if="isPlaying">
            mdi-pause
          </v-icon>
          <v-icon v-else>
            mdi-play
          </v-icon>
        </v-btn>
        <v-btn
          v-if="isPlaying"
          color="danger"
          small
          @click="tripStop"
        >
          <v-icon>mdi-stop</v-icon>
        </v-btn>
      </div>
    </l-control>

    <template v-for="(point, i) in routeCoordinates">
      <l-marker
        :key="i"
        :lat-lng="point"
        @click="showPopupMarkers"
      >
        <l-icon
          v-if="
            isPlaying ? i !== 0 && i !== routeCoordinates.length - 1 : i !== 0
          "
        >
          <v-icon
            v-if="i !== routeCoordinates.length - 1"
            :color="getPolyDotsColor(point)"
            large
          >
            mdi-circle-small
          </v-icon>
          <v-icon
            v-else
            color="#000"
            class="ml-3"
            large
          >
            {{ isPlaying ? "mdi-atv" : "mdi-flag-checkered" }}
          </v-icon>
        </l-icon>
        <l-popup>
          <div class="">
            Latitude:
            <span>{{ popupLatLng.lat?.toFixed(4) }}</span>
          </div>
          <div class="">
            Longitude:
            <span>{{ popupLatLng.lng?.toFixed(4) }}</span>
          </div>
          <div class="">
            Speed:
            <span>{{ popupLatLng?.position?.speed }} km/h</span>
          </div>
          <div
            v-if="vehicleTech === 'ev'"
            class=""
          >
            SOC:
            <span>{{ popupLatLng.state_of_charge }}</span>
          </div>
          <div
            v-if="vehicleTech === 'ice'"
            class=""
          >
            Fuel Level:
            <span>{{ popupLatLng.fuel1 }}</span>
          </div>
          <div class="">
            Date Time:
            <span>{{ popupLatLng.date_time }}</span>
          </div>
        </l-popup>
      </l-marker>
    </template>
    <l-polyline
      :lat-lngs="routeCoordinates"
      :options="getPolylineOptions()"
      @click="showPopupPolyline"
    >
      <!-- Define the LPopup component within the LMap component -->
      <l-popup>
        <div class="">
          Latitude:
          <span>{{ popupLatLng.lat?.toFixed(4) }}</span>
        </div>
        <div class="">
          Longitude:
          <span>{{ popupLatLng.lng?.toFixed(4) }}</span>
        </div>
      </l-popup>
    </l-polyline>
  </l-map>
</template>

<script>
import { latLngBounds } from "leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import {
  LMap,
  LTileLayer,
  LControl,
  LMarker,
  LIcon,
  LPolyline,
  LPopup,
} from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LControl,
    LMarker,
    LIcon,
    LPolyline,
    LPopup,
    LControlFullscreen,
  },
  props: {
    tripsData: {
      type: Array,
      default() {
        return [];
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
    tripPlayBack: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=en",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 16,
      center: [0, 0],
      popupLatLng: [],
      // bounds: latLngBounds([
      //   [40.70081290280357, -74.26963806152345],
      //   [40.82991732677597, -74.08716201782228],
      // ]),
      intervalId: null,
      intervalTime: 1000,
      intervalTimeOptions: [1000, 800, 600, 400, 200],
      //
      count: 1,
      routeCoordinates: [],
      isPlaying: false,
    };
  },
  computed: {
    // routeCoordinates() {
    //   const arr = this.tripsData.map((r) => [
    //     r.position.latitude,
    //     r.position.longitude,
    //   ]);
    //   return arr.filter((r) => r[0]);
    // },
    MapCenter() {
      return this.routeCoordinates[0];
    },
    bounds() {
      let arr = [];
      const routeLength = this.routeCoordinates.length - 1;
      arr.push(this.routeCoordinates[0]);
      arr.push(this.routeCoordinates[routeLength]);
      return latLngBounds(arr);
    },
  },
  mounted() {
    this.tripPlayBackData();
  },
  methods: {
    getPolylineOptions() {
      const polylineOptions = {
        color: "green",
        weight: 3,
        opacity: 0,
        smoothFactor: 1,
      };

      return polylineOptions;
    },
    getPolyDotsColor(val) {
      const detail = this.tripsData.find(
        (r) => r.position.latitude == val[0] && r.position.longitude == val[1]
      );
      return detail.position.speed > 35 ? "red" : "green";
    },
    showPopupPolyline(event) {
      // Get the coordinates of the clicked point
      const latlng = event.latlng;
      this.popupLatLng = latlng;
    },
    showPopupMarkers(event) {
      // Get the coordinates of the clicked point
      const latlng = event.latlng;
      const detail = this.tripsData.find(
        (r) =>
          r.position.latitude == latlng.lat &&
          r.position.longitude == latlng.lng
      );
      // Set the popup coordinates and content
      this.popupLatLng = { ...latlng, ...detail, ...detail.position };
    },
    //
    tripPlayBackData() {
      this.routeCoordinates = [];
      if (this.isPlaying) {
        if (this.count > 0) {
          for (let i = 0; i <= this.count; i++) {
            const r = this.tripsData[i];
            this.routeCoordinates.push([
              r.position.latitude,
              r.position.longitude,
            ]);
          }
        }
        // this.count = 0; // Initialize the index counter outside setInterval
        this.intervalId = setInterval(() => {
          const r = this.tripsData[this.count];
          if (r) {
            this.routeCoordinates.push([
              r.position.latitude,
              r.position.longitude,
            ]);
            this.count++;
            if (this.count === this.tripsData.length) {
              this.tripStop();
            }
          }
        }, this.intervalTime);
      } else {
        const arr = this.tripsData.map((r) => [
          r.position.latitude,
          r.position.longitude,
        ]);
        this.routeCoordinates = arr.filter((r) => r[0]);
      }
    },
    tripPlay() {
      this.isPlaying = true;
      this.tripPlayBackData();
    },
    tripPause() {
      this.isPlaying = false;
      clearInterval(this.intervalId); // Clear interval when all data points are processed
    },
    tripStop() {
      this.isPlaying = false;
      this.count = 1;
      clearInterval(this.intervalId); // Clear interval when all data points are processed
      this.tripPlayBackData();
    },
    setIntervalTime() {
      this.intervalTime -= 200;

      if (this.intervalTime <= 0) this.intervalTime = 1000;
    },
  },
};
</script>
<style lang="sass" scoped>
.VueLeafMap
  position: absolute !important
  width: 100% !important
  height: 100% !important
  top: 0
  left: 0
:deep .leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive
  display: flex
  justify-content: center


.gap-05
  gap: 0.5rem
</style>
